/* eslint-disable jsx-a11y/anchor-has-content */
import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import styles from './Imprint.module.scss';

interface IProps {}

const Imprint: React.FC<PropsWithChildren<IProps>> = _props => {
  const { t } = useTranslation();

  const generalInformationSections = [
    {
      title: t('company'),
      content: t('miosgaCompanyName'),
    },
    {
      title: t('address'),
      content: t('miosgaCompanyAddress'),
    },
    {
      title: t('phone'),
      content: t('miosgaCompanyPhone'),
    },
    {
      title: t('email'),
      content: t('miosgaCompanyEmail'),
    },
    {
      title: t('otherCommunications'),
      content: t('miosgaOtherCommunications'),
    },
    {
      title: t('director'),
      content: t('miosgaCompanyDirector'),
    },
    {
      title: t('registry'),
      content: t('miosgaCompanyRegistry'),
    },
    {
      title: t('vatId'),
      content: t('miosgaCompanyVatId'),
    },
    {
      title: t('smallBusiness'),
      content: t('miosgaCompanySmallBusiness'),
    },
    {
      title: t('disputeResolution'),
      content: (
        <Trans
          i18nKey="disputeResolutionInfo"
          components={{
            Link: <a href="https://ec.europa.eu/consumers/odr">link</a>,
          }}
        />
      ),
    },
  ];

  const legalInfoSections = [
    {
      title: t('termsOfUse'),
      enHref: 'https://www.miosga-software.com/Terms_of_Use_EN.pdf',
      deHref: 'https://www.miosga-software.com/Nutzungsbedingungen_DE.pdf',
    },
    {
      title: t('privacyPolicy'),
      enHref: 'https://www.miosga-software.com/Privacy_Policy_EN.pdf',
      deHref: 'https://www.miosga-software.com/Datenschutzerklaerung_DE.pdf',
    },
  ];

  return (
    <>
      <div className={cn(styles.container, styles.first)}>
        <h1 className={styles.title}>{t('imprintPageTitle')}</h1>

        <div className={styles.generalInfo}>
          {generalInformationSections.map((section, sectionIndex) => {
            return (
              <div key={sectionIndex} className={styles.section}>
                <h3 className={styles.sectionTitle}>{section.title}:</h3>
                <div className={styles.sectionContent}>{section.content}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.hrContainer}>
        <div className={styles.hr}></div>
      </div>
      <div className={cn(styles.container, styles.last)}>
        <div className={styles.legalInfo}>
          {legalInfoSections.map((section, sectionIndex) => {
            return (
              <div key={sectionIndex} className={styles.legalInfoSection}>
                <h3 className={styles.legalInfoSectionTitle}>{section.title}</h3>
                <div className={styles.legalInfoSectionContent}>
                  <div className={styles.legalInfoSectionContentItem}>
                    <a href={section.deHref} rel="noreferrer" target="_blank">
                      {t('downloadDeutsch')}
                    </a>
                  </div>
                  <div className={styles.legalInfoSectionContentItem}>
                    <a href={section.enHref} rel="noreferrer" target="_blank">
                      {t('downloadEnglish')}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Imprint;
